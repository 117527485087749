:root {
  --bg-color-main: #f5f5f5;
  --bg-light-blue: rgba(13, 132, 255, 0.07);
  --bg-dark-gray: #e9e9eb;
  --bg-dark-gray-hover: #d7d7d9;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

@font-face {
  font-family: bpgAlgeti;
  src: url(../../../node_modules/bpg-algeti/fonts/bpg-algeti-webfont.ttf);
}

a,
p,
button,
h1,
h2,
h3,
h4,
h5,
h6,
span {
  font-family: bpgAlgeti;
}

.main-bg {
  background: var(--bg-color-main);
}

.light-blue-bg {
  background: var(--bg-light-blue);
}

.dark-gray-bg {
  background-color: var(--bg-dark-gray);
}

.dark-gray-bg:hover {
  background-color: var(--bg-dark-gray-hover);
}
html {
  scroll-behavior: smooth;
}

/* section display none end third container */
/* width */
::-webkit-scrollbar {
  width: 5px;
}
/*scrolling
/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 30px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(0, 191, 255);
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgb(0, 191, 255);
}

input[type="tel"] {
  outline: none;
  color: #6e7492;
}

.custom-button::file-selector-button {
  background: #3585ff;
  color: white;
  border: 0;
  border-radius: 4px;
  padding: 10px 14px;
}
